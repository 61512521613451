import React, { useState } from 'react';
import Coupons from '../component/c-offers/coupons';
import Discount from '../component/c-offers/discount';
import Complimentary from '../component/c-offers/complimentary';

export default function OffersPage(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="mt-4">
            <div className="px-4">
                <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 0)}>Discount</button>
                <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 1)}>Coupon</button>
                <button className={value === 2 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 2)}>Complimentary</button>
            </div>
            {value === 0 ? (
                <Discount key={value} />
            ) : value === 1 ? (
                <Coupons key={value} />
            ) : value === 2 ? (
                <Complimentary key={value} />
            ) : (
                <></>
            )}
        </div>
    );
}
