import React, { useState } from 'react';
import AllOrderTable from './all-order-table';

export default function AllOrderDetailsTab(props) {
    const [index, setIndex] = useState(0);
    const [searchTxt, setSearchTxt] = useState("");

    const handleChange = (newValue) => {
        setIndex(newValue);
    };

    const handleOrderSearch = async (e) => {
        setSearchTxt(e.target.value)
    };

    return (
        <div className="category-container mt-4">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div>
                    <button className={index === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(0)}>All Orders</button>
                    <button className={index === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(1)}>Submitted</button>
                    <button className={index === 2 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(2)}>Preparing</button>
                    <button className={index === 3 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(3)}>Ready For Pickup</button>
                    <button className={index === 4 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(4)}>Out For Delivery</button>
                    <button className={index === 5 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(5)}>Completed</button>
                    <button className={index === 6 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(6)}>Outlet Canceled</button>
                </div>
                <div className="table-search">
                    <div className="search-container">
                        <input onChange={(e) => handleOrderSearch(e)}
                            type="search"
                            placeholder="Search.."
                            ame="search"
                            autoFocus
                        />

                    </div>
                </div>
            </div>
            <div>
                {index === 0 ? (
                    <AllOrderTable key={index} status="all" orderCunt={props.orderCunt} perPage={20} searchTxt={searchTxt} />
                ) : index === 1 ? (
                    <AllOrderTable key={index} status="submitted" orderCunt={props.orderCunt} perPage={20} />
                ) : index === 2 ? (
                    <AllOrderTable key={index} status="preparing" orderCunt={props.orderCunt} perPage={20} />
                ) : index === 3 ? (
                    <AllOrderTable key={index} status="ready-for-pickup" orderCunt={props.orderCunt} perPage={20} />
                ) : index === 4 ? (
                    <AllOrderTable key={index} status="out-for-delivery" orderCunt={props.orderCunt} perPage={20} />
                ) : index === 5 ? (
                    <AllOrderTable key={index} status="completed" orderCunt={props.orderCunt} perPage={20} />
                ) : index === 6 ? (
                    <AllOrderTable key={index} status="outlet-canceled" orderCunt={props.orderCunt} perPage={20} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
