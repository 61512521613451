import React,{useState} from 'react';
import SlotActiveTable from './slot-active-table';
import SlotExpiryTable from './slot-expiry-table';
export default function SlotTab(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <div>
                <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e)=>handleChange(e,0)}>Active slot</button>
                <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e)=>handleChange(e,1)}>Expiry</button>
            </div>
            <div style={{marginTop:'7px'}}>
                {value === 0 ? (
                    <SlotActiveTable ActData={props.data} myFunction={props.myFunction} />
                ) : (
                    <SlotExpiryTable Expdata={props.data1} />
                )}
            </div>
        </div>
    );
}
