import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import MaintenanceMode from "./MaintenanceMode";

const OrderCancelReason = () => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="mt-1">
        <div className="px-4">
          <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(0)}>Maintenance Mode</button >
          <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(1)}>Others Settings </button >
        </div >
        {value === 0 ? <MaintenanceMode /> : <></>
        }
      </div >
    </>
  );
};

export default OrderCancelReason;
