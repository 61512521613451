import React, { useState, useEffect } from 'react';
import moment from 'moment';
import FoodListTable from './food-list-table';
import OutletOrderListTable from './outlet-order-list-table';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import { Modal } from "react-bootstrap";
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router";
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from "../../store";
import {uploadExcel,allSearch,getOutletOrderStatus} from '../../api/apiService';
import { CSVLink } from "react-csv";
import OutletReports from './outlet-reports';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

export default connect(mapStateToProps)(function FoodTabs(props) {
    const history = useHistory();
    const pagInfo = props.pagInfo;
    const [value, setValue] = React.useState(0);
    const [fItem, setFItem] = React.useState(props.foodItem);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [pageCount, setpageCount] = useState("")
    const [orderPageNo, setOrderPageNo] = useState(1)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [ouletAllOrders, setIOuletAllOrders] = useState([])
    const [excelStatus, setExcelStatus] = useState()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // csv down start  ------
    const [dataDown, setDataDown] = useState([]);
    const [headers, setHeaders] = useState([]);
    const downloadCsv = (csvData) => {
        let data = []
        csvData.forEach(item => {
            let pro = []
            data.push({
                orderDate: moment(item.createdDate).format('MM/DD/YYYY HH:mm'),
                outletName: item.outlet.outlet_name,
                userName: item.user.full_name,
                orderId: item.orderId,
                paymentStatus: item.paymentStatus,
                deliveryAddress: item.deliveryAddress.address,
                pincode: item.deliveryAddress.pincode,
                // deliveryCharge: item.deliveryCharge,
                discount: item.discount,
                orderAmount: item.orderAmount,
                product: pro
            });
            for (let i = 0; i < item.foods.length; i++) {
                const role = item.foods[i];
                pro.push(role.product.product_name)
            }
        });
        let header = [
            { label: "Order Date", key: "orderDate" },
            { label: "Outlet Name", key: "outletName" },
            { label: "User Name", key: "userName" },
            { label: "Order Id", key: "orderId" },
            { label: "Payment Status", key: "paymentStatus" },
            { label: "Delivery Address", key: "deliveryAddress" },
            { label: "Pincode", key: "pincode" },
            // { label: "Delivery Charge", key: "deliveryCharge" },
            { label: "Discount", key: "discount" },
            { label: "Order Amount", key: "orderAmount" },
            { label: "Product", key: "product" }
        ];
        setDataDown(data);
        setHeaders(header)
    }
    // csv down End  ------
    const handleStartDate = (e) => {
        const date = moment.utc(e.target.value).format("YYYY-MM-DD")
        setStartDate(date);
    }
    const handleEndDate = (e) => {
        const date = moment.utc(e.target.value).format("YYYY-MM-DD")
        setEndDate(date);
    }
    // validation for orders search------------------
    const valid = () => {
        let checked = true;
        if (startDate === "") {
            checked = false;
        }
        if (endDate === "") {
            checked = false;
        }
        return checked;
    }
    // Date by order search submit -------------------------
    const dateByOrderSearch = (e) => {
        e.preventDefault()
        setIsSubmitted(true);
        if (valid()) {
            getOutletOrder()
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    useEffect(() => {
        setFItem(props.foodItem);
        getOutletOrder()
    }, [props.foodItem]);
    const handleProductSearch = (e) => {
        // setProductSearch(e.target.value)
        if (e.target.value.length < 1) {
            setFItem(props.foodItem)
        } else {
            ProductSearch(e.target.value)
        }
    }

    const handleOrderPageNo = (m) => {
        setOrderPageNo(o => m.selected + 1)
        getOutletOrder();
    }
    // Api call------------
    const getOutletOrder = async () => {
        store.dispatch(loader(true));
        try {
            const orders = await getOutletOrderStatus(props.data, "all", startDate, endDate, orderPageNo);
            setIOuletAllOrders(orders.data.data)
            setpageCount(orders.data.pageInfo)
            downloadCsv(orders.data.data)
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    const ProductSearch = async (txt) => {
        try {
            const search = await allSearch("product", encodeURIComponent(txt), props.data);
            setFItem(search.data.data)
        } catch (ex) {
            console.log(ex);
        }
    }
    const handleUploadExcel = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            store.dispatch(loader(true));
            try {
                const allExcelFile = await uploadExcel(props.data, file);
                store.dispatch(loader(false));
                handleShow();
                setExcelStatus(allExcelFile.data.data);
            } catch (error) {
                console.log(error);
                store.dispatch(loader(false));
            }
        }

    };


    return (
        <div className="category-container mb-4">
            <div className="d-flex justify-content-sm-between">
                <span>
                    <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 0)}>Reports</button>
                    <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 1)}>Foods</button>
                    <button className={value === 2 ? 'panel-btn is-active' : 'panel-btn'} onClick={(e) => handleChange(e, 2)}>Orders</button>
                </span>
                <div className="d-flex">
                    <div className="table-search mr-2">
                        {value === 1 ?
                            <div className="search-container">
                                <input
                                    onChange={(e) => handleProductSearch(e)}
                                    type="search" placeholder="Search.." name="search" autoFocus />
                            </div>
                            :
                            value === 2 ?
                                <div className="d-flex">
                                    <form className="d-flex my-2 mr-2">
                                        <div className="form-group m-0 mr-2 d-flex align-items-center">
                                            <b className="mr-1">To:</b>
                                            <input
                                                onChange={(e) => handleStartDate(e)}
                                                style={startDate.length < 8 && isSubmitted ? { border: "1px solid red" } : {}}
                                                type="date"
                                                value={startDate}
                                                className="form-tab-field"
                                            />
                                        </div>
                                        <div className="form-group m-0 mr-2 d-flex align-items-center">
                                            <b className="mr-1">From:</b>
                                            <input
                                                onChange={(e) => handleEndDate(e)}
                                                style={endDate.length < 8 && isSubmitted ? { border: "1px solid red" } : {}}
                                                type="date"
                                                value={endDate}
                                                className="form-tab-field"
                                            />
                                        </div>
                                        <button className="btn btn-verify mr-2"
                                            onClick={dateByOrderSearch}
                                            title="Search Orders">
                                            <SearchIcon />
                                        </button>
                                    </form>
                                    <CSVLink data={dataDown} headers={headers} filename={"Hungry-top.csv"}>
                                        <Tooltip title="Download CSV File" placement="top" arrow>
                                            <button className="btn btn-verify my-2 mr-2">
                                                <CloudDownloadIcon />
                                            </button>
                                        </Tooltip>
                                    </CSVLink>
                                </div>
                                :
                                <div></div>
                        }
                    </div>
                    {value === 1 ?
                        <div>
                            <input type="file" id="excelFile" onChange={(e) => handleUploadExcel(e)} />
                            <label className="btn btn-verify my-2 mr-2" htmlFor="excelFile">Excel Upload</label>
                            <button onClick={() => history.push(`/outlets/product-info/${props.data}`)} type="button" className="btn btn-verify my-2">Add Food</button>
                        </div>
                        :
                        <div></div>
                    }

                </div>
            </div>
            <div>
                {value===0?(
                    <OutletReports />
                ):value==1?(
                    <FoodListTable foodItem={fItem} pagInfo={pagInfo} foodPageFunc={props.foodPageFunc} foodStatus={props.foodStatus} foodDelete={props.foodDelete} rerenderParentCallback={props.rerenderParentCallback}/>
                ):(
                    <OutletOrderListTable ouletAllOrders={ouletAllOrders} handleOrderPageNo={handleOrderPageNo} pageCount={pageCount} />
                )}
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                keyboard={false}
                centered
            >
                <Modal.Header closebutton>
                    <Modal.Title><h3>Excel Upload Status</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex">
                        <div className="w-50 mr-1">
                            <h4 className="text-center upload-success">Upload Success</h4>
                            <ul className="list-group">
                                {
                                    excelStatus ? excelStatus.successList.map(m => {
                                        return <li key={m} className="list-group-item">{m}</li>
                                    }) : <div></div>
                                }
                            </ul>
                        </div>
                        <div className="w-50">
                            <h4 className="text-center upload-field">Upload Field</h4>
                            <ul className="list-group">
                                {
                                    excelStatus ? excelStatus.errorList.map(m => {
                                        return <li key={m} className="list-group-item">{m}</li>
                                    }) : <div></div>
                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={handleClose} type="button" className="btn btn-cancel">Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
})
