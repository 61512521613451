import React, { Component, useEffect, useState } from 'react';
import FormSetings from '../component/c-settings/formSetings';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { getAllSettings, updateAllSettings, } from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Divider, Tooltip } from '@mui/material';


const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

const AppSettings = () => {
    const [settingsAll, setSettingsAll] = useState([]), [isUpdate, setIsUpdate] = useState(false);
    const [paymentOnline, setPaymentOnline] = useState(true), [cashOnDelivery, setCashOnDelivery] = useState(true);
    const [homeDelivery, setHomeDelivery] = useState(true), [takeaway, setTakeaway] = useState(true);
    const [forceClose, setForceClose] = useState(false);

    // const changePaymentOnline = (e) => {
    //     if (window.confirm("Do you want to change Payment Mode?")) {
    //         setPaymentOnline(e.target.checked);
    //     }
    // }
    // const changeCashOnDelivery = (e) => {
    //     if (window.confirm("Do you want to change Cash On delivery?")) {
    //         setCashOnDelivery(e.target.checked);
    //     }
    // }
    // const changeHomeDelivery = (e) => {
    //     if (window.confirm("Do you want to change Home Delivery?")) {
    //         setHomeDelivery(e.target.checked);
    //     }
    // }
    // const changeTakeaway = (e) => {
    //     if (window.confirm("Do you want to change Takeaway?")) {
    //         setTakeaway(e.target.checked);
    //     }
    // }
    // const changeForceClose = (e) => {
    //     if (window.confirm(`Do you want to ${e.target.checked ? 'close' : 'open'} all outlet?`)) {
    //         setForceClose(e.target.checked);
    //     }
    // }

    /*
    * Child
    * Component to function call
    */
    const handleSubmit = async () => {
        if (window.confirm('Do you want to change the payment & delivery settings?')) {
            await parentFunctionCall();
            setIsUpdate(false);
        }
    }
    const parentFunctionCall = (e) => {
        const data = {
            paymentOnline, cashOnDelivery, homeDelivery, takeaway, forceClose,
            deliveryPrice: e?.delivaryCharage, platformCharge: e?.platformCharge,
            autoSubmit: settingsAll.autoSubmit, extraAd: settingsAll.extraAd
        }
        updateSettings(data)
    }
    // Api call /////////////////
    /*
    * Api
    * Get All Settings
    */
    const allSettings = async () => {
        store.dispatch(loader(true));
        try {
            const settings = await getAllSettings();
            setSettingsAll(settings.data.data);
            setPaymentOnline(settings.data.data.paymentMode.online);
            setCashOnDelivery(settings.data.data.paymentMode.cashOnDelivery);
            setHomeDelivery(settings.data.data.deliveryMode.homeDelivery);
            setTakeaway(settings.data.data.deliveryMode.takeaway);
            setForceClose(settings.data.data.forceClose);
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    /*
    * Api
    * Update All Settings
    */
    const updateSettings = async (data) => {
        store.dispatch(loader(true));

        try {
            const settings = await updateAllSettings(data);
            console.log(settings);
            if (settings.data?.status === 'success') {
                allSettings();
                ToastSuccess(settings.data?.message)
            } else {
                ToastError("Something went wrong, Please try again")
            }
        } catch (ex) {
            console.log(ex);
        } finally {
            store.dispatch(loader(false));
        }
    }
    useEffect(() => { allSettings() }, []);

    return (
        <>
            {settingsAll.length === 0 ?
                <></> :
                <div className="container-fluid">
                    <div className="my-3">
                        <div className="row">
                            <div className="col-sm-6 mt-3">
                                <FormSetings
                                    parentFunctionCall={parentFunctionCall}
                                    delivaryCharage={settingsAll.deliveryPrice}
                                    platformCharge={settingsAll.platformCharge}
                                />
                            </div>
                            <div className="col-sm-6 mt-3">
                                <div className="category-container">
                                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h5 className="text-secondary">Settings</h5>
                                        <Tooltip title="Update settings" arrow>
                                            <i className="fas fa-edit" style={{ cursor: "pointer", color: "blue" }} onClick={() => setIsUpdate(true)} />
                                        </Tooltip>
                                    </span>
                                    <Divider sx={{ width: "100%", backgroundColor: '#000' }} />
                                    <div>
                                        <div className="form-group mb-2">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={paymentOnline} onChange={(e) => isUpdate && setPaymentOnline(e.target.checked)} name="Online Payment" />}
                                                    label="Online Payment"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={cashOnDelivery} onChange={(e) => isUpdate && setCashOnDelivery(e.target.checked)} name="Cash On Delivery" />}
                                                    label="Cash On Delivery"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={homeDelivery} onChange={(e) => isUpdate && setHomeDelivery(e.target.checked)} name="Home Delivery" />}
                                                    label="Home Delivery"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={takeaway} onChange={(e) => isUpdate && setTakeaway(e.target.checked)} name="Takeaway" />}
                                                    label="Takeaway"
                                                />

                                                <FormControlLabel
                                                    control={<Checkbox checked={forceClose} onChange={(e) => isUpdate && setForceClose(e.target.checked)} name="Outlet Force Close" />}
                                                    label="Outlet Force Close"
                                                />
                                            </FormGroup>
                                            <div className="text-right mt-2">
                                                <button className="btn btn-verify" onClick={() => handleSubmit()} hidden={!isUpdate}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default connect(mapStateToProps)(AppSettings);