import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { ToastSuccess, ToastError } from "../toast-notification";
import { Modal } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import BackspaceIcon from "@material-ui/icons/Backspace";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import {
  getOutlet,
  outletActiveStatus,
  addOutletManager,
  getComplementaries,
  assignComplementaries,
  deleteComplimentOutlet,
  outletActiveOpenStatus,
} from "../../api/apiService";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../Actions/index";
import { Tooltip } from "@material-ui/core";

const OutletResturentDetails = (props) => {
  const dispatch = useDispatch();
  const loaderDisplay = useSelector((state) => state.loaderDisplay);
  const time = moment().local().format("HH:mm");
  const times = moment().local();
  const date = moment().local().format("YYYY-MM-DD");

  // State declarations
  const [singleOutletDetails, setSingleOutletDetails] = useState([]);
  const [checkedOutletActive, setCheckedOutletActive] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [resturent, setResturent] = useState("");
  const [resturentClosingTime, setResturentClosingTime] = useState("");
  const [resturentBreak, setResturentBreak] = useState("");
  const [resturentBreakTimeIn, setResturentBreakTimeIn] = useState("");
  const [resturentBreakTimeStart, setResturentBreakTimeStart] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [allComplimentary, setAllComplimentary] = useState([]);
  const [complimentary, setComplimentary] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    getAllComplimentary();
    singleOutlet();
  }, []);

  const handleModalClose = (type) => {
    setModalShow(!modalShow);
    setModalType(type);
  };

  const handleMobileNo = (e) => {
    setMobileNo(e.target.value);
    if (isSubmitted) {
      valid();
    }
  };

  const valid = () => {
    let checked = true;
    if (modalType === "manager") {
      if (mobileNo.length !== 10) {
        checked = false;
      }
      return checked;
    }
    if (modalType === "complimantory") {
      if (complimentary === "") {
        checked = false;
      }
      return checked;
    }
  };

  const getAllComplimentary = async () => {
    try {
      const compliment = await getComplementaries();
      setAllComplimentary(compliment.data.data);
      ToastSuccess(compliment.data.data.message);
    } catch (ex) {
      console.log(ex);
      ToastError(ex.response.data.message);
    }
  };

  const assignComplement = async () => {
    let data = {
      outlets: [singleOutletDetails._id],
    };
    try {
      const assign = await assignComplementaries(complimentary, data);
      ToastSuccess(assign.data.message);
      setModalShow(false);
      setModalType(false);
      setIsSubmitted(false);
      setComplimentary("");
      singleOutlet();
    } catch (ex) {
      console.log(ex);
      ToastError(ex.message);
    }
  };

  const newManagerAdd = async () => {
    dispatch(loader(true));
    let data = {
      mobile: mobileNo,
    };
    try {
      await addOutletManager(props.data, data);
      setModalShow(false);
      setMobileNo("");
      setIsSubmitted(false);
      singleOutlet();
      dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(loader(false));
    }
  };

  const handleChangeStatus = async () => {
    try {
      const resStatus = await outletActiveStatus(props.data);
      setCheckedOutletActive(!checkedOutletActive);
      ToastSuccess(resStatus.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error);
      ToastError(error.response.data.message);
    }
  };

  const handleChangeOpenStatus = async () => {
    try {
      const resStatus = await outletActiveOpenStatus(props.data);
      setIsOpen(!isOpen);
      ToastSuccess(resStatus.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error);
      ToastError(error.response.data.message);
    }
  };

  const singleOutlet = async () => {
    try {
      const response = await getOutlet(props.data);
      setSingleOutletDetails(response.data.data);
      setCheckedOutletActive(response.data.data.isActive);
      setIsOpen(response.data.data.isOpen);

      var now = moment(new Date());
      var end = moment(`${date} ${response.data.data.closing_time}`);
      var duration = moment.duration(end.diff(now));
      var sec = duration.asSeconds();
      var minutes = (sec % 3600) / 60;
      var hours = sec / 3600;
      setResturentClosingTime(`${hours.toFixed()}:${minutes.toFixed()}`);

      if (
        time > response.data.data.opening_time &&
        time < response.data.data.closing_time
      ) {
        setResturent("open");
      } else {
        setResturent("closed");
      }

      var endS = moment(`${date} ${response.data.data.break_start}`);
      var durations = moment.duration(endS.diff(now));
      setResturentBreakTimeStart(String(durations.format("hh:mm:ss")));

      var endin = moment(`${date} ${response.data.data.break_end}`);
      var durationin = moment.duration(endin.diff(now));
      setResturentBreakTimeIn(String(durationin.format("hh:mm:ss")));

      if (time <= response.data.data.break_start) {
        setResturentBreak("breakstart");
      }
      if (response.data.data.break_end>time > response.data.data.break_start) {
        setResturentBreak("breakin");
      }
      if (time >= response.data.data.break_end) {
        setResturentBreak("breakclose");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCompliOutletAndOutlet = async () => {
    let outletId = singleOutletDetails._id;
    if (window.confirm("Do you want to delete the complimentaries ?")) {
      try {
        const complimentDelete = await deleteComplimentOutlet(outletId, "outlet");
        ToastSuccess(complimentDelete.data.message);
        singleOutlet();
      } catch (ex) {
        console.log(ex);
        ToastError(ex.message);
      }
    }
  };

  const handleMobileNoAdd = () => {
    setIsSubmitted(true);
    if (valid()) {
      if (modalType === "manager") {
        newManagerAdd();
      }
      if (modalType === "complimantory") {
        assignComplement();
      }
    }
  };

  return (
    <>
      {singleOutletDetails.length === 0 ? (
        <div></div>
      ) : (
        <div className="category-container my-3">
          <div className="resturent-details-container">
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-5">
                    <div className="details-img-container">
                      <img src={singleOutletDetails.outlet_images} alt="" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="bg-white p-2">
                      <p className="resturent-name">
                        {singleOutletDetails.outlet_name}
                      </p>
                      {singleOutletDetails.isActive && (
                        <>
                          <div className="d-flex align-items-center mb-2">
                            <div
                              className={`tag-icon ${
                                resturent === "open"
                                  ? "success-badge"
                                  : "danger-badge"
                              } mr-1`}
                            >
                              <UpdateOutlinedIcon />
                            </div>
                            <div>
                              {resturent === "open" ? (
                                <span style={{ color: "#32c975" }}>
                                  Close in {resturentClosingTime} hours
                                </span>
                              ) : (
                                <span style={{ color: "#ffa54e" }}>Closed</span>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            {resturentBreak === "breakin" || "breakin" ? (
                              <div className="tag-icon danger-badge mr-1">
                                <UpdateOutlinedIcon />
                              </div>
                            ) : <></>}

                            <div>
                              {resturentBreak === "breakstart" ? (
                                <span style={{ color: "#ffa54e" }}>
                                  Break Start in {resturentBreakTimeStart}
                                </span>
                              ) : resturentBreak === "breakin" ? (
                                <span style={{ color: "#ffa54e" }}>
                                  Now break time, Reopen in {resturentBreakTimeIn}
                                </span>
                              ) : resturentBreak==="breakclose"?(
                                <></>
                              ):(<></>)}
                            </div>
                          </div>
                        </>
                      )}
                      <p className="resturent-address">
                        {singleOutletDetails.address_details.address}
                      </p>
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                          <span className="font-12 font-weight-bold">
                            Cost For Two ₹{singleOutletDetails.cost_for_two}
                          </span>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div>
                            <span className="font-12 font-weight-bold">
                              Active / Deactive
                            </span>
                            <Switch
                              checked={checkedOutletActive}
                              onChange={handleChangeStatus}
                              name="checkedOutletActive"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                          {singleOutletDetails.isActive && (
                            <div>
                              <span className="font-12 font-weight-bold">
                                Open / Closed
                              </span>
                              <Switch
                                checked={isOpen}
                                onChange={handleChangeOpenStatus}
                                name="isOpen"
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {singleOutletDetails.isActive && (
                <div className="col-md-5">
                  <div>
                    <div className="resturent-time-box mr-2">
                      <p className="resturent-time-title">Resturent Time</p>
                      <div className="d-flex">
                        <div className="resturent-time success-badge mr-2">
                          <span className="time-type">Open</span>
                          <span className="time">
                            {singleOutletDetails.opening_time}
                          </span>
                        </div>
                        <div className="resturent-time danger-badge">
                          <span className="time-type">Close</span>
                          <span className="time">
                            {singleOutletDetails.closing_time}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="resturent-time-box">
                      <p className="resturent-time-title">Delivery Time</p>
                      <div className="d-flex">
                        <div className="resturent-time success-badge mr-2">
                          <span className="time-type">Start</span>
                          <span className="time">
                            {singleOutletDetails.delivery_start_time}
                          </span>
                        </div>
                        <div className="resturent-time danger-badge">
                          <span className="time-type">End</span>
                          <span className="time">
                            {singleOutletDetails.delivery_end_time}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="resturent-time-box">
                      <p className="resturent-time-title">Break Time</p>
                      <div className="d-flex">
                        <div className="resturent-time success-badge mr-2">
                          <span className="time-type">Start</span>
                          <span className="time">
                            {singleOutletDetails.break_start}
                          </span>
                        </div>
                        <div className="resturent-time danger-badge">
                          <span className="time-type">End</span>
                          <span className="time">
                            {singleOutletDetails.break_end}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-end flex-wrap">
                    <div>
                      <p className="text-capitalize">
                        <b>
                          {singleOutletDetails.managerDetails !== null
                            ? singleOutletDetails.managerDetails.full_name
                            : ""}
                        </b>
                      </p>
                      <button
                        onClick={() => handleModalClose("manager")}
                        type="button"
                        className="btn btn-verify"
                      >
                        {singleOutletDetails.managerDetails !== null
                          ? "Change manager"
                          : "Add manager"}
                      </button>
                    </div>
                    <div className="m-2"></div>
                    <div>
                      <div>
                        {!singleOutletDetails.complementary ||
                        singleOutletDetails.complementary === "null" ? (
                          ""
                        ) : (
                          <span className="text-capitalize badge bg-light text-dark font-15 my-2">
                            {singleOutletDetails.complementary.name}
                            <Tooltip
                              title="Delete complimantory"
                              placement="right"
                              arrow
                            >
                              <BackspaceIcon
                                onClick={() => deleteCompliOutletAndOutlet()}
                                style={{
                                  fontSize: "16px",
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: "3px",
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                      <button
                        onClick={() => handleModalClose("complimantory")}
                        type="button"
                        className="btn btn-verify"
                      >
                        {!singleOutletDetails.complementary ||
                        singleOutletDetails.complementary === "null"
                          ? "Add"
                          : "Change"}{" "}
                        complimantary
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        show={modalShow}
        onHide={() => handleModalClose()}
        backdrop="static"
        size="sm"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              {modalType === "manager" ? "manager" : "Complimantory"}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "manager" ? (
            <>
              <div className="form-group m-0">
                <label className="font-12 m-0">Mobile no</label>
                <input
                  onChange={(e) => handleMobileNo(e)}
                  style={
                    mobileNo.length !== 10 && isSubmitted
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  value={mobileNo}
                  className="form-control"
                  placeholder="Enter your mobile number"
                />
              </div>
              <div className="text-right mt-2">
                <button
                  onClick={() => handleMobileNoAdd()}
                  type="button"
                  className="btn btn-verify"
                >
                  Add
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                className="category-field mb-2"
                style={
                  complimentary === "" && isSubmitted
                    ? { border: "1px solid red", borderRadius: "4px" }
                    : { border: "1px solid #cccccc", borderRadius: "4px" }
                }
              >
                <Multiselect
                  isObject={true}
                  onRemove={(value) => {
                    setComplimentary("");
                    if (isSubmitted) {
                      valid();
                    }
                  }}
                  onSelect={(value) => {
                    setComplimentary(value[0]._id);
                    if (isSubmitted) {
                      valid();
                    }
                  }}
                  options={allComplimentary}
                  displayValue="name"
                  placeholder="Select complimentaries"
                  selectionLimit={1}
                  closeOnSelect={true}
                />
              </div>
              <div className="text-right my-3">
                <button
                  onClick={() => handleMobileNoAdd()}
                  type="button"
                  className="btn btn-verify"
                  data-toggle="modal"
                >
                  Add For Outlet
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OutletResturentDetails;