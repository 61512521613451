import React,{useState} from 'react';
import ActiveFoods from './active-foods-table';
import ExpairyFoodTable from './expairy-foods-table';
import UpcomingFoodsTable from './upcoming-foods-table';

export default function AuctionTab(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div>
                <button className={value===0?'panel-btn is-active':'panel-btn'} onClick={(e)=>handleChange(e,0)}>Active</button>
                <button className={value===1?'panel-btn is-active':'panel-btn'} onClick={(e)=>handleChange(e,1)}>Up Coming</button>
                <button className={value===2?'panel-btn is-active':'panel-btn'} onClick={(e)=>handleChange(e,2)}>Expired</button>
            </div>
            <div className='mt-2'>
                {value===0?(
                    <ActiveFoods key={value} data={props.data.activeFoodList} />
                ):value===1?(
                    <UpcomingFoodsTable key={value} data={props.data.upcomingFoodList} rerenderParentCallback={props.rerenderParentCallback}/>
                ):value===2?(
                    <ExpairyFoodTable key={value} data={props.data.expireFoodList} />
                ):(
                    <></>
                )}
            </div>
        </div>
    );
}
