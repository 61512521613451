import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, Tooltip } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import { createOrderCancelReason, getAllOrderCancelReason, getSingleOrderCancelReason, updateOrderCancelReason, deleteOrderCancelReason } from "../api/apiService";
  
const OrderCancelReason = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [isActiveTab, setIsActiveTab] = useState('active');
  const [btnDisable, setBtnDisable] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateId, setUpdateId] = useState('');
  const [allReasons, setAllReasons] = useState([]);
  const [cancelReason, setCancelReason] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [availableForAdmin, setAvailableForAdmin] = useState(true);
  const [availableForRestaurant, setAvailableForRestaurant] = useState(false);
  const [availableForCustomer, setAvailableForCustomer] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setIsActiveTab('active');
    } else {
      setIsActiveTab('inactive');
    }
  };
  const fetchData1 = () => {
    try {
      getAllOrderCancelReason(isActiveTab)
        .then((response) => {
          console.log(response);
          if (response && response.data && response.data.status === 200) {
            setAllReasons(response.data?.data);
          } else {
            toast.warn(response.data?.message);
          }
        });
    } catch (error) {
      console.log('Fetching order cancel reasons: ', error);
      toast.error('Something went wrong...');
    }
  }
  const handleSingleOrderCancelReason = (id) => {
    try {
      getSingleOrderCancelReason(id)
        .then((response) => {
          // console.log(response);
          if (response && response.data && response.data.status === 200) {
            setIsUpdate(true);
            setUpdateId(response.data?.data?._id);
            setCancelReason(response.data?.data?.cancelReason);
            setIsActive(response.data?.data?.isActive);
            setAvailableForAdmin(response.data?.data?.availableForAdmin);
            setAvailableForRestaurant(response.data?.data?.availableForRestaurant);
            setAvailableForCustomer(response.data?.data?.availableForCustomer);
          } else {
            toast.warn(response.data?.message);
          }
        })
    } catch (error) {
      console.log('Fetching order cancel reasons: ', error);
      toast.error('Something went wrong...');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const bodyData = { cancelReason, isActive, availableForAdmin, availableForRestaurant, availableForCustomer };
    try {
      setBtnDisable(true);
      if (isUpdate) {
        updateOrderCancelReason(updateId, bodyData)
          .then((response) => {
            console.log(response)
            if (response && response.data && response.data.status === 200) {
              toast.success(response.data.message);
              setIsUpdate(false);
              handleReset();
              fetchData1();
            } else {
              toast.warn(response.data.message);
            }
          });
      } else {
        createOrderCancelReason(bodyData)
          .then((response) => {
            console.log(response)
            if (response && response.data && response.data.status === 200) {
              toast.success(response.data.message);
              handleReset();
              fetchData1();
            } else {
              toast.warn(response.data.message);
            }
          });
      }
    } catch (error) {
      console.log(isUpdate ? 'Updating' : 'Creating', ' order cancel reason error: ', error);
      toast.error('Something went wrong...');
    } finally {
      setBtnDisable(false);
    }
  }
  const handleDelete = (e, id) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelbutton: true,
        confirmbuttonColor: '#3085d6',
        cancelbuttonColor: '#d33',
        confirmbuttonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteOrderCancelReason(id)
            .then((response) => {
              if (response && response.data && response.data.status === 200) {
                toast.success(response.data.message);
                fetchData1();
              } else {
                toast.warn(response.data.message);
              }
            });
        } else {
          toast.warn("Unsuccessfull operation. please try again")
        }
      });
    } catch (error) {
      console.log('deleting order cancel reason error: ', error);
      toast.error('Something went wrong...');
    }
  }

  const handleReset = () => {

    setCancelReason('');
    setIsActive(true);
    setAvailableForRestaurant(false);
    setAvailableForCustomer(false);
  }
  useEffect(() => {
    fetchData1();
  }, [isActiveTab]);

  return (
    <>
      <div className='container-fluid'>
        <div className="row my-4">
          <div className="col-sm-8">
            <div className="category-container">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-secondary mb-1">All Order Cancel Reasons:</h5>
                <span className="mb-1">
                  <button className={value===0?'panel-btn is-active':'panel-btn'} onClick={(e)=>handleChange(e,0)}>Active Reasons</button>
                  <button className={value===1?'panel-btn is-active':'panel-btn'} onClick={(e)=>handleChange(e,1)}>Inactive Reasons</button>
                </span>
              </div>
              <div >
                
                {value===0?(
                    <>
                      {allReasons.length > 0 ? (
                        <>
                          <table className="table dash-table table-hover text-secondary">
                            <thead>
                              <tr className="text-left">
                                <th style={{ width: '5%' }}>Sl No.</th>
                                <th style={{ width: '45%' }}>Reason</th>
                                <th style={{ width: '10%' }}>Active?</th>
                                <th style={{ width: '10%' }}>Available for Admin</th>
                                <th style={{ width: '10%' }}>Available for Restaurant</th>
                                <th style={{ width: '10%' }}>Available for Customer</th>
                                <th style={{ width: '10%' }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allReasons.map((reason, index) => (
                                <tr key={index}>
                                  <td>{index + 1}.</td>
                                  <td className="text-left">{reason.cancelReason}</td>
                                  <td>{reason.isActive ? <i className="fa fa-check" style={{ color: 'blue' }} aria-hidden="true" /> : <i className="fa-solid fa-xmark" style={{ color: 'red' }} />}</td>
                                  <td>{reason.availableForAdmin ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>{reason.availableForRestaurant ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>{reason.availableForCustomer ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>
                                    <Tooltip title='Want to Edit?' arrow>
                                      <i className="fas fa-edit" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleSingleOrderCancelReason(reason._id)} />
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title='Want to Delete?' arrow>
                                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323" cursor='pointer' onClick={(e) => handleDelete(e, reason._id)}>
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>No Data Found...</p>
                      )}
                    </>
                ):(
                    <>
                      {allReasons.length > 0 ? (
                        <>
                          <table className="table dash-table table-hover text-secondary">
                            <thead>
                              <tr className="text-left">
                                <th style={{ width: '5%' }}>Sl No.</th>
                                <th style={{ width: '45%' }}>Reason</th>
                                <th style={{ width: '10%' }}>Active?</th>
                                <th style={{ width: '10%' }}>Available for Admin</th>
                                <th style={{ width: '10%' }}>Available for Restaurant</th>
                                <th style={{ width: '10%' }}>Available for Customer</th>
                                <th style={{ width: '10%' }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allReasons.map((reason, index) => (
                                <tr key={index}>
                                  <td>{index + 1}.</td>
                                  <td className="text-left">{reason.cancelReason}</td>
                                  <td>{reason.isActive ? <i className="fa fa-check" style={{ color: 'blue' }} aria-hidden="true" /> : <i className="fa-solid fa-xmark" style={{ color: 'red' }} />}</td>
                                  <td>{reason.availableForAdmin ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>{reason.availableForRestaurant ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>{reason.availableForCustomer ? <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true" /> : null}</td>
                                  <td>
                                    <Tooltip title='Want to Edit?' arrow>
                                      <i className="fas fa-edit" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleSingleOrderCancelReason(reason._id)} />
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title='Want to Delete?' arrow>
                                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#EA3323" cursor='pointer' onClick={(e) => handleDelete(e, reason._id)}>
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>No Data Found...</p>
                      )}
                    </>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="category-container">
              <div className="d-flex justify-content-between">
                <h5 className="text-secondary ml-2">Create Order Cancel Reason</h5>
                <button className="clear-btn" onClick={handleReset}>Clear</button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="category-field">
                  <div className="form-group m-0">
                    <label>Please provide the reason:</label>
                    <textarea
                      onChange={(e) => setCancelReason(e.target.value)}
                      className="form-control"
                      value={cancelReason}
                    />
                  </div>
                  <div className="form-group m-0">
                    <label>Status:</label>
                    <select
                      onChange={(e) => setIsActive(e.target.value === 'true')}
                      className="form-control"
                      value={isActive}
                    >
                      <option value='true'>Active</option>
                      <option value='false'>Inactive</option>
                    </select>
                  </div>
                  <div>
                    <Checkbox
                      checked={availableForAdmin}
                      onChange={(e) => setAvailableForAdmin(e.target.checked)}
                    />
                    <label>Option available for <b>Admin</b></label>
                  </div>
                  <div>
                    <Checkbox
                      checked={availableForRestaurant}
                      onChange={(e) => setAvailableForRestaurant(e.target.checked)}
                    />
                    <label>Option available for <b>Restaurant</b></label>
                  </div>
                  <div>
                    <Checkbox
                      checked={availableForCustomer}
                      onChange={(e) => setAvailableForCustomer(e.target.checked)}
                    />
                    <label>Option available for <b>Customer</b></label>
                  </div>
                </div>
                <button className={btnDisable ? "btn-disable" : "btn-verify"} type="submit" disabled={btnDisable}>{isUpdate ? 'Update' : 'Submit'}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default OrderCancelReason;
