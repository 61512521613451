import api, { order, auction } from "./index";

const login = (mobile) => {
  return api.get(`/login/${mobile}`);
}
const deleteUser = (fullName, mobile) => {
  return api.delete(`/user`, { data: { full_name: fullName, mobile: mobile } });
};

const report = () => {
  return api.get(`/report/dashboard`);
}
const getDateByOrders = (startDate, endDate, pageNo) => {
  return api.get(`/report/order-by-date?startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}`);
}
const reportSubscriptionPlan = () => {
  return api.get(`/report/subscription-users`);
}
const getReportOutlet = (outletId, pageNo, type) => {
  return api.get(`/report/outlet/${outletId}/orders?currentPage=${pageNo}&type=${type}`);
}
const getReportOutletDaily = (outletId, date, pageNo, type) => {
  return api.get(`/report/outlet/${outletId}/orders/daily?month=${date}&currentPage=${pageNo}&type=${type}`);
}
const getReportOutletMonthly = (outletId, date, pageNo, type) => {
  return api.get(`/report/outlet/${outletId}/orders/monthly?month=${date}&currentPage=${pageNo}&type=${type}`);
}
const getMonthlyReport = (date) => {
  return api.get(`/report/monthly-report?month=${date}`);
}
const searchOrders = (txt) => {
  return order.get(`/order/search/${txt}`);
}
const getProfileDetils = () => {
  return api.get(`/me`);
}
const getAllUsers = (userType, pageNo) => {
  return api.get(`/get-all-user?type=${userType}&currentPage=${pageNo}`);
}
const changeNormalUser = (actionUserId) => {
  return api.patch(`/user/update/normal/${actionUserId}`);
}
const getOwner = (pageNo) => {
  return api.get(`/get-all-owner?currentPage=${pageNo}`);
}

const createNewOwner = (bodyData) => {
  return api.post(`/create-new-owner`, bodyData);
}

const getAlluserOrders = (userId, pageNo, perPage) => {
  return order.get(`/order/user-orders/${userId}?currentPage=${pageNo}&perPage=${perPage}`);
}

const addOutletManager = (outletId, bodyData) => {
  return api.post(`/add-outlet-manager/${outletId}`, bodyData);
}

const verifyOtpCreateOwner = (bodyData) => {
  return api.post(`/verify-otp-create-owner`, bodyData);
}
const getAllOutlet = (pageNo) => {
  return api.get(`/get-all-outlet?currentPage=${pageNo}`);
}
const getOutletByOwner = (ownerId) => {
  return api.get(`/get-all-outlets/${ownerId}`);
}
const userDetails = (userId) => {
  return api.get(`/user-details/${userId}`);
}

const userActiveStatus = (actionUserId) => {
  return api.patch(`/user-active-status/${actionUserId}`);
}

const userUpdateAdmin = (actionUserId) => {
  return api.patch(`/user-update-admin/${actionUserId}`);
}

const userUpdateMerchant = (actionUserId) => {
  return api.patch(`/user-update-merchant/${actionUserId}`);
}

const userUpdate = (actionUserId, bodyData) => {
  return api.put(`/user/${actionUserId}`, bodyData);
}
const userUpdateByAdmin = (actionUserId, bodyData) => {
  return api.put(`/user-update-by-admin/${actionUserId}`, bodyData);
}
const updateUserVerifyOtpByAdmin = (actionUserId, bodyData) => {
  return api.post(`/user-otp-verify-by-admin/${actionUserId}`, bodyData);
}
const addNewOutlet = (bodyData) => {
  return api.post(`/create-outlet`, bodyData);
}
const addProduct = (outletId, bodyData) => {
  return api.post(`/add-product/${outletId}`, bodyData);
}
const outletUpdate = (actionOutletId, bodyData) => {
  return api.put(`/update-outlet/${actionOutletId}`, bodyData);
}
const getOutlet = (outletId) => {
  return api.get(`/get-outlet/${outletId}`);
}
const getCategory = () => {
  return api.get(`/main-category`);
}
const mainProduct = (outletId) => {
  return api.get(`/outlet-main-products/${outletId}`);
}
const uploadImage = (image, folder) => {
  const fromData = new FormData();
  fromData.append(
    'file',
    image,
    image.name
  )
  return api.post(`/upload_image?folder=${folder}`, fromData);
}

const uploadExcel = (outletId, file) => {
  const fromData = new FormData();
  fromData.append(
    'file',
    file,
    file.name
  )
  return api.post(`/upload-excel-menu/${outletId}`, fromData);
}
const addProductImg = (productId, bodyData) => {
  return api.put(`update-product-image/${productId}`, bodyData);
}
const addOutletImg = (outletId, bodyData) => {
  return api.put(`update-outlet-image/${outletId}`, bodyData);
}
const getSingleProduct = (productId) => {
  return api.get(`/outlet-single-product/${productId}`);
}
const productUpdate = (productId, bodyData) => {
  return api.put(`/update-product/${productId}`, bodyData);
}
const addAddon = (productId, bodyData) => {
  return api.put(`/add-addon-product/${productId}`, bodyData);
}
const deleteAddon = (productId,index, addonProductId) => {
  return api.delete(`/delete-addon-product/${productId}/${index}/${addonProductId}`);
}
const addCustomization = (productId, bodyData) => {
  return api.post(`/add-customization/${productId}`,bodyData);
}
const addSingleAddon = (productId,index, bodyData) => {
  return api.post(`/add-single-addon-product/${productId}/${index}`,bodyData);
}
const updateAddon = (productId,index, bodyData) => {
  return api.put(`/update-addon-product/${productId}/${index}`,bodyData);
}
const outletActiveStatus = (outletId) => {
  return api.patch(`/outlet-active-status/${outletId}`);
}
const outletActiveOpenStatus = (outletId) => {
  return api.patch(`/outlet/open-status/${outletId}`);
}
const productActiveStatus = (productId) => {
  return api.patch(`/product-active-status/${productId}`);
}
const productDeleteStatus = (productId) => {
  return api.patch(`/product-delete-status/${productId}`);
}
const allCategory = () => {
  return api.get(`/main-category`);
}
const addCategory = (bodyData) => {
  return api.post(`/create-category`, bodyData);
}
const addSubCategory = (categoryId, bodyData) => {
  return api.put(`/add-sub-category/${categoryId}`, bodyData);
}
const updateCategory = (categoryId, bodyData) => {
  return api.put(`/update-category/${categoryId}`, bodyData);
}
const addRefferal = (bodyData) => {
  return api.post(`/referral`, bodyData);
}
const getRefferal = () => {
  return api.get(`/referral`);
}
const updateReferral = (referId, bodyData) => {
  return api.put(`/referral/${referId}`, bodyData);
}
const referralStatus = (referId) => {
  return api.patch(`/referral-status/${referId}`);
}
const getSlot = () => {
  return auction.get(`/auction/all-slots`);
}
const createSlot = (bodyData) => {
  return auction.post(`/auction/create-slot`, bodyData);
}
const copySlot = (date) => {
  return auction.get(`/auction/slot-copy-next-day/${date}`);
}
const getActiveFoods = () => {
  return auction.get(`/auction/active-foods-admin`);
}
const getAuctionDetails = (slotBookId) => {
  return auction.get(`/auction/auction-details/${slotBookId}`);
}
const auctionFoodStatus = (bookingId) => {
  return auction.get(`/auction/active-foods-status/${bookingId}`);
}
const getAuctionBidDetails = (slotBookId) => {
  return auction.get(`/auction/auction-bid-details/${slotBookId}`);
}
const getUserAuctionDetails = (actionUserId) => {
  return auction.get(`/auction/user-auctions/${actionUserId}`);
}
const actionBookSlot = (bodyData) => {
  return auction.post(`/auction/book-slot`, bodyData);
}
const postMultiNotify = (userId, bodyData) => {
  return api.post(`/send-notification/${userId}`, bodyData);
}
const postTopicNotify = (topic, bodyData) => {
  return api.post(`/send-topic-notification/${topic}`, bodyData);
}
const getAllNotifications = () => {
  return api.get(`/notifications`);
}
const allSearch = (type, text, id) => {
  return api.get(`/admin/search/${type}/${text}?outletId=${id}`);
}
const getAllOrders = (type, pagNo, perpage) => {
  return order.get(`/order/all-orders?status=${type}&currentPage=${pagNo}&perPage=${perpage}`);
}
const getOutletOrderStatus = (outletId, status, startDate, endDate, orderPageNo) => {
  return order.get(`/order/outlet-orders-status/${outletId}/${status}?startDate=${startDate}&endDate=${endDate}&currentPage=${orderPageNo}&perPage=20`);
}
const getUserOrdersDetails = (outletId) => {
  return order.get(`/order/order-details/${outletId}`);
}
const changeOrderStatus = (orderId,status,cancelReason,note) => {
  // let url=`/order/change-orders-status/${orderId}/${status}`
  // if(cancelReason&&cancelReason.length>0){
  //   url+=`/${cancelReason}`
  // }
  // if(note&&note.length>0){
  //   url+=`/${note}`
  // }
  // return order.patch(url);
  return order.patch(`/order/change-orders-status?orderId=${orderId}&status=${status}&cancelReason=${cancelReason}&note=${note}`);
}

const getAllSettings = () => {
  return api.get(`/settings/get-all-settings-admin`);
}
const updateAllSettings = (bodyData) => {
  return api.put(`/settings/update-all-settings`, bodyData);
}
const updateDashboardAd = (bodyData) => {
  return api.put(`/settings/update-dashboard-ad`, bodyData);
}
const createSubscription = (bodyData) => {
  return api.post(`/settings/create-subsciption`, bodyData);
}
const updateSubscription = (planNo, bodyData) => {
  return api.put(`/settings/update-subsciption/${planNo}`, bodyData);
}
const createOffer = (dataBody) => {
  return api.post(`/offer/create`, dataBody);
}
const getOffer = () => {
  return api.get(`/offer/offers`);
}
const updateOffer = (offerId, bodyData) => {
  return api.put(`/offer/update/${offerId}`, bodyData);
}
const deleteOffer = (offerId) => {
  return api.delete(`/offer/delete/${offerId}`);
}
const addOutletOffers = (offerId, bodyData) => {
  return api.put(`/offer/add-outlets/${offerId}`, bodyData);
}
const addProductOffers = (offerId, outletId, bodyData) => {
  return api.put(`/offer/add-outlet-products/${offerId}/outlet/${outletId}`, bodyData);
}
const getOfferOutlet = (offerId) => {
  return api.get(`/offer/offer/${offerId}`);
}
const deleteOfferOutlet = (id, type) => {
  return api.delete(`/offer/delete-offer/outlet/${id}?type=${type}`);
}
// --------Coupons ------------
const getCoupons = () => {
  return api.get(`/offer/coupons`);
}
const createCoupon = (bodyDyta) => {
  return api.post(`/offer/coupon/create`, bodyDyta);
}
const couponUpdate = (couponId, bodyData) => {
  return api.put(`/offer/coupon/update/${couponId}`, bodyData);
}
const deleteCouponCode = (couponId) => {
  return api.delete(`/offer/coupon/delete/${couponId}`);
}
const initiateCoupon = (couponId, bodyData) => {
  return api.put(`/offer/coupon/initiate/${couponId}`, bodyData);
}
// -----Complimentaries-----------
const getComplementaries = () => {
  return api.get(`/offer/complementaries`);
}
const createComplementaries = (dataBody) => {
  return api.post(`/offer/complementary/create`, dataBody);
}
const UpdateComplementaries = (complementaryId, dataBody) => {
  return api.put(`/offer/complementary/update/${complementaryId}`, dataBody);
}
const deleteComplementaries = (complementaryId) => {
  return api.delete(`/offer/complementary/delete/${complementaryId}`);
}
const assignComplementaries = (complementaryId, bodyData) => {
  return api.put(`/offer/complementary/assign/${complementaryId}`, bodyData);
}
const deleteComplimentOutlet = (id, type) => {
  return api.delete(`/offer/delete-complementary/outlet/${id}?type=${type}`);
}
// Bank verificaton -----------------
const verifyBankAccount = (userId) => {
  return api.get(`/user/verify-bank-account-details/${userId}`);
}
const resetBankAccount = (userId) => {
  return api.get(`/user/reset-bank-account-details/${userId}`);
}
const newBankAccount = (bodyData) => {
  return api.post(`/user/bank-account-details`, bodyData);
}
const verifyKycDetails = (bodyData) => {
  return api.put(`/user/verify/kyc-details`, bodyData);
}

/*
* Subscription 
* Details
*/
const subscriptionUsers = (sDate, eDate, pageNo, searchText, planNo) => {
  if (!searchText)
    searchText = "";
  if (!planNo)
    planNo = "";
  return api.get(`/report/subscription-users?startDate=${sDate}&endDate=${eDate}&pageNo=${pageNo}&search=${searchText}&plan=${planNo}`)
}
const downloadSubscriptionUsers = (csvStartDate, csvEndDate, csvSubsTitle) => {
  if (!csvSubsTitle)
    csvSubsTitle = "";
  return api.get(`/report/download-subscription-users?startDate=${csvStartDate}&endDate=${csvEndDate}&plan=${csvSubsTitle}`)
}

/*
* Order
* Cancel
* Reason
*/
const createOrderCancelReason = (bodyData) => {
  return api.post(`/settings/create-order-cancel-reason`, bodyData);
}
const getAllOrderCancelReason = (isActive,availableForAdmin) => {
  let url=`/settings/get-all-order-cancel-reason`
  if(isActive){
    url+=`?status=${isActive}`
  }
  if(availableForAdmin){
    url+=`?availableForAdmin=${availableForAdmin}`
  }
  return api.get(url);
}
const getSingleOrderCancelReason = (id) => {
  return api.get(`/settings/get-single-order-cancel-reason?id=${id}`,);
}
const updateOrderCancelReason = (id,bodyData) => {
  return api.put(`/settings/update-order-cancel-reason?id=${id}`,bodyData);
}
const deleteOrderCancelReason = (id,bodyData) => {
  return api.delete(`/settings/delete-order-cancel-reason?id=${id}`,);
}
const updateMaintenanceMode = (bodyData) => {
  return api.put(`/settings/update-maintenance-mode`,bodyData);
}
export {
  login,
  deleteUser,
  report,
  getProfileDetils,
  getAllUsers,
  changeNormalUser,
  getOwner,
  createNewOwner,
  getAlluserOrders,
  verifyOtpCreateOwner,
  getAllOutlet,
  addOutletManager,
  userDetails,
  userActiveStatus,
  userUpdateAdmin,
  userUpdate,
  userUpdateByAdmin,
  updateUserVerifyOtpByAdmin,
  addNewOutlet,
  addAddon,
  addProduct,
  uploadImage,
  uploadExcel,
  addProductImg,
  addOutletImg,
  outletUpdate,
  getOutlet,
  getCategory,
  mainProduct,
  getSingleProduct,
  productUpdate,
  deleteAddon,
  updateAddon,
  addSingleAddon,
  addCustomization,
  outletActiveStatus,
  outletActiveOpenStatus,
  productActiveStatus,
  productDeleteStatus,
  allCategory,
  addCategory,
  addSubCategory,
  updateCategory,
  addRefferal,
  getRefferal,
  updateReferral,
  referralStatus,
  getSlot,
  createSlot,
  copySlot,
  getOutletByOwner,
  getActiveFoods,
  getAuctionDetails,
  auctionFoodStatus,
  getAuctionBidDetails,
  getUserAuctionDetails,
  actionBookSlot,
  postMultiNotify,
  postTopicNotify,
  getAllNotifications,
  allSearch,
  getAllOrders,
  getOutletOrderStatus,
  getUserOrdersDetails,
  changeOrderStatus,
  getDateByOrders,
  reportSubscriptionPlan,
  getReportOutlet,
  getReportOutletDaily,
  getReportOutletMonthly,
  getMonthlyReport,
  searchOrders,
  getAllSettings,
  updateAllSettings,
  updateDashboardAd,
  createSubscription,
  updateSubscription,
  createOffer,
  getOffer,
  updateOffer,
  deleteOffer,
  addOutletOffers,
  addProductOffers,
  getOfferOutlet,
  deleteOfferOutlet,
  getCoupons,
  createCoupon,
  couponUpdate,
  deleteCouponCode,
  initiateCoupon,
  getComplementaries,
  createComplementaries,
  UpdateComplementaries,
  deleteComplementaries,
  assignComplementaries,
  deleteComplimentOutlet,
  verifyBankAccount,
  resetBankAccount,
  newBankAccount,
  verifyKycDetails,
  subscriptionUsers,
  userUpdateMerchant,
  downloadSubscriptionUsers,

  createOrderCancelReason,
  getAllOrderCancelReason,
  getSingleOrderCancelReason,
  updateOrderCancelReason,
  deleteOrderCancelReason,
  updateMaintenanceMode,
}




















// import api, { order, auction } from "./index";

// const login = (mobile) => {
//   return api.get(`/login/${mobile}`);
// }
// const deleteUser = (fullName, mobile) => {
//   return api.delete(`/user`, { data: { full_name: fullName, mobile: mobile } });
// }

// const report = () => {
//   return api.get(`/report/dashboard`);
// }
// const getDateByOrders = (startDate, endDate, pageNo) => {
//   return api.get(`/report/order-by-date?startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}`);
// }
// const reportSubscriptionPlan = () => {
//   return api.get(`/report/subscription-users`);
// }
// const getReportOutlet = (outletId, pageNo, type) => {
//   return api.get(`/report/outlet/${outletId}/orders?currentPage=${pageNo}&type=${type}`);
// }
// const getReportOutletDaily = (outletId, date, pageNo, type) => {
//   return api.get(`/report/outlet/${outletId}/orders/daily?month=${date}&currentPage=${pageNo}&type=${type}`);
// }
// const getReportOutletMonthly = (outletId, date, pageNo, type) => {
//   return api.get(`/report/outlet/${outletId}/orders/monthly?month=${date}&currentPage=${pageNo}&type=${type}`);
// }
// const getMonthlyReport = (date) => {
//   return api.get(`/report/monthly-report?month=${date}`);
// }
// const searchOrders = (txt) => {
//   return order.get(`/order/search/${txt}`);
// }
// const getProfileDetils = () => {
//   return api.get(`/me`);
// }
// const getAllUsers = (userType, pageNo) => {
//   return api.get(`/get-all-user?type=${userType}&currentPage=${pageNo}`);
// }
// const changeNormalUser = (actionUserId) => {
//   return api.patch(`/user/update/normal/${actionUserId}`);
// }
// const getOwner = (pageNo) => {
//   return api.get(`/get-all-owner?currentPage=${pageNo}`);
// }

// const createNewOwner = (bodyData) => {
//   return api.post(`/create-new-owner`, bodyData);
// }

// const getAlluserOrders = (userId, pageNo, perPage) => {
//   return order.get(`/order/user-orders/${userId}?currentPage=${pageNo}&perPage=${perPage}`);
// }

// const addOutletManager = (outletId, bodyData) => {
//   return api.post(`/add-outlet-manager/${outletId}`, bodyData);
// }

// const verifyOtpCreateOwner = (bodyData) => {
//   return api.post(`/verify-otp-create-owner`, bodyData);
// }
// const getAllOutlet = (pageNo) => {
//   return api.get(`/get-all-outlet?currentPage=${pageNo}`);
// }
// const getOutletByOwner = (ownerId) => {
//   return api.get(`/get-all-outlets/${ownerId}`);
// }
// const userDetails = (userId) => {
//   return api.get(`/user-details/${userId}`);
// }

// const userActiveStatus = (actionUserId) => {
//   return api.patch(`/user-active-status/${actionUserId}`);
// }

// const userUpdateAdmin = (actionUserId) => {
//   return api.patch(`/user-update-admin/${actionUserId}`);
// }

// const userUpdateMerchant = (actionUserId) => {
//   return api.patch(`/user-update-merchant/${actionUserId}`);
// }

// const userUpdate = (actionUserId, bodyData) => {
//   return api.put(`/user/${actionUserId}`, bodyData);
// }
// const addNewOutlet = (bodyData) => {
//   return api.post(`/create-outlet`, bodyData);
// }
// const addProduct = (outletId, bodyData) => {
//   return api.post(`/add-product/${outletId}`, bodyData);
// }
// const outletUpdate = (actionOutletId, bodyData) => {
//   return api.put(`/update-outlet/${actionOutletId}`, bodyData);
// }
// const getOutlet = (outletId) => {
//   return api.get(`/get-outlet/${outletId}`);
// }
// const getCategory = () => {
//   return api.get(`/main-category`);
// }
// const mainProduct = (outletId) => {
//   return api.get(`/outlet-main-products/${outletId}`);
// }
// const uploadImage = (image, folder) => {
//   const fromData = new FormData();
//   fromData.append(
//     'file',
//     image,
//     image.name
//   )
//   return api.post(`/upload_image?folder=${folder}`, fromData);
// }

// const uploadExcel = (outletId, file) => {
//   const fromData = new FormData();
//   fromData.append(
//     'file',
//     file,
//     file.name
//   )
//   return api.post(`/upload-excel-menu/${outletId}`, fromData);
// }
// const addProductImg = (productId, bodyData) => {
//   return api.put(`update-product-image/${productId}`, bodyData);
// }
// const addOutletImg = (outletId, bodyData) => {
//   return api.put(`update-outlet-image/${outletId}`, bodyData);
// }
// const getSingleProduct = (productId) => {
//   return api.get(`/outlet-single-product/${productId}`);
// }
// const productUpdate = (productId, bodyData) => {
//   return api.put(`/update-product/${productId}`, bodyData);
// }
// const addAddon = (productId, bodyData) => {
//   return api.put(`/add-addon-product/${productId}`, bodyData);
// }
// const updateAddon = (productId,index, bodyData) => {
//   return api.put(`/update-addon-product/${productId}/${index}`, bodyData);
// }
// const deleteAddon = (productId, addonProductId) => {
//   return api.delete(`/delete-addon-product/${productId}/${addonProductId}`);
// }
// const addCustomization = (productId, bodyData) => {
//   return api.post(`/add-customization/${productId}`,bodyData);
// }
// const addSingleAddon = (productId,index, bodyData) => {
//   return api.post(`/add-single-addon-product/${productId}/${index}`,bodyData);
// }
// const outletActiveStatus = (outletId) => {
//   return api.patch(`/outlet-active-status/${outletId}`);
// }
// const outletActiveOpenStatus = (outletId) => {
//   return api.patch(`/outlet/open-status/${outletId}`);
// }
// const productActiveStatus = (productId) => {
//   return api.patch(`/product-active-status/${productId}`);
// }
// const productDeleteStatus = (productId) => {
//   return api.patch(`/product-delete-status/${productId}`);
// }
// const allCategory = () => {
//   return api.get(`/main-category`);
// }
// const addCategory = (bodyData) => {
//   return api.post(`/create-category`, bodyData);
// }
// const addSubCategory = (categoryId, bodyData) => {
//   return api.put(`/add-sub-category/${categoryId}`, bodyData);
// }
// const updateCategory = (categoryId, bodyData) => {
//   return api.put(`/update-category/${categoryId}`, bodyData);
// }
// const addRefferal = (bodyData) => {
//   return api.post(`/referral`, bodyData);
// }
// const getRefferal = () => {
//   return api.get(`/referral`);
// }
// const updateReferral = (referId, bodyData) => {
//   return api.put(`/referral/${referId}`, bodyData);
// }
// const referralStatus = (referId) => {
//   return api.patch(`/referral-status/${referId}`);
// }
// const getSlot = () => {
//   return auction.get(`/auction/all-slots`);
// }
// const createSlot = (bodyData) => {
//   return auction.post(`/auction/create-slot`, bodyData);
// }
// const copySlot = (date) => {
//   return auction.get(`/auction/slot-copy-next-day/${date}`);
// }
// const getActiveFoods = () => {
//   return auction.get(`/auction/active-foods-admin`);
// }
// const getAuctionDetails = (slotBookId) => {
//   return auction.get(`/auction/auction-details/${slotBookId}`);
// }
// const auctionFoodStatus = (bookingId) => {
//   return auction.get(`/auction/active-foods-status/${bookingId}`);
// }

// const getAuctionBidDetails = (slotBookId) => {
//   return auction.get(`/auction/auction-bid-details/${slotBookId}`);
// }
// const getUserAuctionDetails = (actionUserId) => {
//   return auction.get(`/auction/user-auctions/${actionUserId}`);
// }
// const actionBookSlot = (bodyData) => {
//   return auction.post(`/auction/book-slot`, bodyData);
// }
// const postMultiNotify = (userId, bodyData) => {
//   return api.post(`/send-notification/${userId}`, bodyData);
// }
// const postTopicNotify = (topic, bodyData) => {
//   return api.post(`/send-topic-notification/${topic}`, bodyData);
// }
// const getAllNotifications = () => {
//   return api.get(`/notifications`);
// }
// const allSearch = (type, text, id) => {
//   return api.get(`/admin/search/${type}/${text}?outletId=${id}`);
// }
// const getAllOrders = (type, pagNo, perpage) => {
//   return order.get(`/order/all-orders?status=${type}&currentPage=${pagNo}&perPage=${perpage}`);
// }
// const getOutletOrderStatus = (outletId, status, startDate, endDate, orderPageNo) => {
//   return order.get(`/order/outlet-orders-status/${outletId}/${status}?startDate=${startDate}&endDate=${endDate}&currentPage=${orderPageNo}&perPage=20`);
// }
// const getUserOrdersDetails = (outletId) => {
//   return order.get(`/order/order-details/${outletId}`);
// }
// const changeOrderStatus = (orderId, status) => {
//   return order.patch(`/order/change-orders-status/${orderId}/${status}`);
// }

// const getAllSettings = () => {
//   return api.get(`/settings/get-all-settings-admin`);
// }
// const updateAllSettings = (bodyData) => {
//   return api.put(`/settings/update-all-settings`, bodyData);
// }
// const updateDashboardAd = (bodyData) => {
//   return api.put(`/settings/update-dashboard-ad`, bodyData);
// }
// const createSubscription = (bodyData) => {
//   return api.post(`/settings/create-subsciption`, bodyData);
// }
// const updateSubscription = (planNo, bodyData) => {
//   return api.put(`/settings/update-subsciption/${planNo}`, bodyData);
// }
// const createOffer = (dataBody) => {
//   return api.post(`/offer/create`, dataBody);
// }
// const getOffer = () => {
//   return api.get(`/offer/offers`);
// }
// const updateOffer = (offerId, bodyData) => {
//   return api.put(`/offer/update/${offerId}`, bodyData);
// }
// const deleteOffer = (offerId) => {
//   return api.delete(`/offer/delete/${offerId}`);
// }
// const addOutletOffers = (offerId, bodyData) => {
//   return api.put(`/offer/add-outlets/${offerId}`, bodyData);
// }
// const addProductOffers = (offerId, outletId, bodyData) => {
//   return api.put(`/offer/add-outlet-products/${offerId}/outlet/${outletId}`, bodyData);
// }
// const getOfferOutlet = (offerId) => {
//   return api.get(`/offer/offer/${offerId}`);
// }
// const deleteOfferOutlet = (id, type) => {
//   return api.delete(`/offer/delete-offer/outlet/${id}?type=${type}`);
// }
// // --------Coupons ------------
// const getCoupons = () => {
//   return api.get(`/offer/coupons`);
// }
// const createCoupon = (bodyDyta) => {
//   return api.post(`/offer/coupon/create`, bodyDyta);
// }
// const couponUpdate = (couponId, bodyData) => {
//   return api.put(`/offer/coupon/update/${couponId}`, bodyData);
// }
// const deleteCouponCode = (couponId) => {
//   return api.delete(`/offer/coupon/delete/${couponId}`);
// }
// const initiateCoupon = (couponId, bodyData) => {
//   return api.put(`/offer/coupon/initiate/${couponId}`, bodyData);
// }
// // -----Complimentaries-----------
// const getComplementaries = () => {
//   return api.get(`/offer/complementaries`);
// }
// const createComplementaries = (dataBody) => {
//   return api.post(`/offer/complementary/create`, dataBody);
// }
// const UpdateComplementaries = (complementaryId, dataBody) => {
//   return api.put(`/offer/complementary/update/${complementaryId}`, dataBody);
// }
// const deleteComplementaries = (complementaryId) => {
//   return api.delete(`/offer/complementary/delete/${complementaryId}`);
// }
// const assignComplementaries = (complementaryId, bodyData) => {
//   return api.put(`/offer/complementary/assign/${complementaryId}`, bodyData);
// }
// const deleteComplimentOutlet = (id, type) => {
//   return api.delete(`/offer/delete-complementary/outlet/${id}?type=${type}`);
// }
// const deletefood = (id, type) => {
//   return api.patch(`/product/delete-status/${id}`);
// }
// // Bank verificaton -----------------
// const verifyBankAccount = (userId) => {
//   return api.get(`/user/verify-bank-account-details/${userId}`);
// }
// const resetBankAccount = (userId) => {
//   return api.get(`/user/reset-bank-account-details/${userId}`);
// }
// const newBankAccount = (bodyData) => {
//   return api.post(`/user/bank-account-details`, bodyData);
// }
// const verifyKycDetails = (bodyData) => {
//   return api.put(`/user/verify/kyc-details`, bodyData);
// }

// /*
// * Subscription 
// * Details
// */
// const subscriptionUsers = (sDate, eDate, pageNo, searchText, planNo) => {
//   if (!searchText)
//     searchText = "";
//   if (!planNo)
//     planNo = "";
//   return api.get(`/report/subscription-users?startDate=${sDate}&endDate=${eDate}&pageNo=${pageNo}&search=${searchText}&plan=${planNo}`)
// }
// export {
//   login,
//   deleteUser,
//   report,
//   getProfileDetils,
//   getAllUsers,
//   changeNormalUser,
//   getOwner,
//   createNewOwner,
//   getAlluserOrders,
//   verifyOtpCreateOwner,
//   getAllOutlet,
//   addOutletManager,
//   userDetails,
//   userActiveStatus,
//   userUpdateAdmin,
//   userUpdate,
//   addNewOutlet,
//   addAddon,
//   addProduct,
//   uploadImage,
//   uploadExcel,
//   addProductImg,
//   addOutletImg,
//   outletUpdate,
//   getOutlet,
//   getCategory,
//   mainProduct,
//   getSingleProduct,
//   productUpdate,
//   updateAddon,
//   deleteAddon,
//   addCustomization,
//   addSingleAddon,
//   outletActiveStatus,
//   outletActiveOpenStatus,
//   productActiveStatus,
//   productDeleteStatus,
//   allCategory,
//   addCategory,
//   addSubCategory,
//   updateCategory,
//   addRefferal,
//   getRefferal,
//   updateReferral,
//   referralStatus,
//   getSlot,
//   createSlot,
//   copySlot,
//   getOutletByOwner,
//   getActiveFoods,
//   getAuctionDetails,
//   auctionFoodStatus,
//   getAuctionBidDetails,
//   getUserAuctionDetails,
//   actionBookSlot,
//   postMultiNotify,
//   postTopicNotify,
//   getAllNotifications,
//   allSearch,
//   getAllOrders,
//   getOutletOrderStatus,
//   getUserOrdersDetails,
//   changeOrderStatus,
//   getDateByOrders,
//   reportSubscriptionPlan,
//   getReportOutlet,
//   getReportOutletDaily,
//   getReportOutletMonthly,
//   getMonthlyReport,
//   searchOrders,
//   getAllSettings,
//   updateAllSettings,
//   updateDashboardAd,
//   createSubscription,
//   updateSubscription,
//   createOffer,
//   getOffer,
//   updateOffer,
//   deleteOffer,
//   addOutletOffers,
//   addProductOffers,
//   getOfferOutlet,
//   deleteOfferOutlet,
//   getCoupons,
//   createCoupon,
//   couponUpdate,
//   deleteCouponCode,
//   initiateCoupon,
//   getComplementaries,
//   createComplementaries,
//   UpdateComplementaries,
//   deleteComplementaries,
//   assignComplementaries,
//   deleteComplimentOutlet,
//   verifyBankAccount,
//   resetBankAccount,
//   newBankAccount,
//   verifyKycDetails,
//   subscriptionUsers,
//   userUpdateMerchant
// }