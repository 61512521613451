import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import DeliverySettings from './DeliverySettings';
import OrderCancelReason from './OrderCancelReason';

const OrderSettings = () => {
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <div className="mt-1">
            <div className="px-4">
                    <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(0)}>Delivery Settings</button>
                    <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(1)}> Order Cancel Reason</button>
            </div>
            <div>
                {value === 0 ? <DeliverySettings /> : <OrderCancelReason />}
            </div>
        </div>
    );
};

export default OrderSettings;
