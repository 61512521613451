import React, { useState, useEffect } from 'react';
import UsersTable from './users-table';
import RastaurantTable from './restaurant-owner-table';
import ManagerTable from './manager-table';
import AdminTable from './admin-table';
import { allSearch } from '../../api/apiService';

const UserTab = (props) => {
    const [isActive, setIsActive] = useState(0);
    const [value, setValue] = useState(0);
    const [uSearch, setUSearch] = useState("");
    const [uData, setUdata] = useState(props.data);

    const handleChange = (newValue) => {
        setIsActive(newValue);
        setValue(newValue);
        setUSearch("");
        setUdata(props.data);
    };

    const handleUserSearch = (event) => {
        setUSearch(event.target.value);
        if (event.target.value.length < 1) {
            setUdata(props.data);
        } else {
            userSearch(event.target.value);
        }
    };

    useEffect(() => {
        setUdata(props.data);
    }, [props.data]);

    const userSearch = async (txt) => {
        let searchType = value === 0 ? "user" :
                        value === 1 ? "owner" :
                        value === 2 ? "manager" : "admin";

        try {
            const search = await allSearch(searchType, encodeURIComponent(txt));
            const info = {
                data: search.data.data || [],
                message: uData.message,
                pageInfo: {
                    currentPage: 1,
                    itemLength: 15,
                    itemPerPage: 15,
                    maxPageNo: 1,
                    nextPageNo: 1
                },
                status: uData.status,
                userCount: uData.userCount,
            };
            setUdata(info);
        } catch (ex) {
            console.error(ex);
        }
    };

    return (
        <div className="category-container mb-4">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div>
                    <button className={isActive === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(0)}>Users</button>
                    <button className={isActive === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(1)}>Restaurant Owner</button>
                    <button className={isActive === 2 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(2)}>Manager</button>
                    <button className={isActive === 3 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(3)}>Admin</button>
                </div>
                <div className="table-search">
                    <div className="search-container">
                        <input 
                            onChange={(e) => handleUserSearch(e)} 
                            value={uSearch} 
                            type="search" 
                            placeholder="Search.." 
                            name="search" 
                            autoFocus 
                        />
                    </div>
                </div>
            </div>
            <div>
                {value === 0 ? (
                    <UsersTable
                        data={uData}
                        allUserType={props.allUserType}
                        userPageFunc={props.userPageFunc} 
                    />
                ) : value === 1 ? (
                    <RastaurantTable
                        data={uData}
                        allUserType={props.allUserType}
                        userPageFunc={props.userPageFunc} 
                    />
                ) : value === 2 ? (
                    <ManagerTable
                        data={uData}
                        allUserType={props.allUserType}
                        userPageFunc={props.userPageFunc} 
                    />
                ) : (
                    <AdminTable
                        data={uData}
                        allUserType={props.allUserType}
                        userPageFunc={props.userPageFunc} 
                    />
                )}
            </div>
        </div>
    );
};

export default UserTab;
