import React, { Component, useEffect, useState } from 'react';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import CategoryIcon from '@material-ui/icons/Category';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { NavLink } from 'react-router-dom';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ReportIcon from '@material-ui/icons/Report';
import { getAllSettings } from '../api/apiService';
import packageInfo from '../../package.json'

const SideManu = () => {
    const [subReport, setSubReport] = useState(false);
    const [settingsAll, setSettingsAll] = useState(null);
    const subManu = () => { setSubReport(!subReport) }
    const allSettings = async () => {
        try {
            const settings = await getAllSettings();
            setSettingsAll(settings.data.data);
        } catch (ex) {
            console.log(ex);
        }
    };
    useEffect(() => { allSettings(); }, []);
    useEffect(() => {
        console.log("settingsAll: ", settingsAll);
    })
    return (
        <>
            <div className='side-menu-container'>
                <ul className="menu">
                    <li>
                        <NavLink className="menu-item" to="/dashboard" activeClassName='is-active'><i className="demo-icon">&#xe80d;</i><span className="ml-2">Dashboard</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/users-management" activeClassName='is-active'><i className="demo-icon">&#xe821;</i><span className="ml-2">Users</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/outlets" activeClassName='is-active'><i className="demo-icon">&#xe816;</i><span className="ml-2"></span>Outlet</NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/Orders" activeClassName='is-active'><i className="demo-icon">&#xe81b;</i><span className="ml-2"></span>Orders</NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/auction-slot" activeClassName='is-active'><FastfoodIcon className="m-0" /><span className="ml-2">Auction Slot</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/auction" activeClassName='is-active'><i className="demo-icon">&#xe804;</i><span className="ml-2">Auction</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/Reference" activeClassName='is-active'><i className="demo-icon">&#xe81a;</i><span className="ml-2">Reference</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/notification" activeClassName='is-active'><i className="demo-icon">&#xe815;</i><span className="ml-2">Notification</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/category" activeClassName='is-active'><CategoryIcon className="m-0" /><span className="ml-2">Categorise</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/subscription" activeClassName='is-active'><CardMembershipIcon className="m-0" /><span className="ml-2">Subscription</span></NavLink>
                    </li>
                    <li>
                        <NavLink className="menu-item" to="/offers" activeClassName='is-active'><LoyaltyIcon className="m-0" /><span className="ml-2">Discount</span></NavLink>
                    </li>
                    <li>
                        <span onClick={() => subManu()} className="menu-item d-flex justify-content-between">
                            <span>
                                <i className="fa fa-file"></i>
                                <span className="ml-2">Report</span>
                            </span>
                            <i className="fa fa-caret-down" style={subReport ? { transform: "rotate(0deg)" } : { transform: "rotate(269deg)" }}></i>
                        </span>
                        {subReport ?
                            <ul className="ml-2">
                                {/* <li>
                                    <NavLink className="menu-item" to="/report/over-view" activeClassName='is-active'><ReportIcon />Overview</NavLink>
                                </li> */}
                                <li>
                                    <NavLink className="menu-item" to="/report/orderReportPage" activeClassName='is-active'><ReportIcon />Orders Report</NavLink>
                                </li>
                                <li>
                                    <NavLink className="menu-item" to="/track-on-map" activeClassName='is-active'><ReportIcon />Track On Map</NavLink>
                                </li>
                            </ul>
                            :
                            ""
                        }

                    </li>
                    <li>
                        <NavLink className="menu-item" to="/settings" activeClassName='is-active'><i className="demo-icon">&#xe81d;</i><span className="ml-2">Settings</span></NavLink>
                    </li>
                </ul>
            </div>
            <div className="version-footer">
                <span>Admin Version: {packageInfo.version}</span>
                <br />
                <span>App Version: {settingsAll?.androidAppVersionCode} ({settingsAll?.androidAppVersionName})</span>
            </div>
        </>
    );
}

export default SideManu;