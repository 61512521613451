import { Divider, Tooltip } from '@mui/material';
import React, { Component, useState } from 'react';

const FormSetings = (props) => {

    const [isSubmitted, setIsSubmitted] = useState(false), [isUpdate, setIsUpdate] = useState(false);
    const [delivaryCharage, setDelivaryCharage] = useState(props.delivaryCharage.toFixed(2)), [platformCharge, setPlatformCharge] = useState(props.platformCharge.toFixed(2));

    const valid = () => {
        let checked = true;
        if (delivaryCharage === "" || platformCharge === "") {
            checked = false;
        }
        return checked
    }
    /*
    * Form Submit button
    */
    const submitted = () => {
        setIsSubmitted(true);
        if (valid()) {
            const data = { delivaryCharage, platformCharge }
            if (window.confirm("Do you want to change delivary Charage")) {
                props.parentFunctionCall(data);
                setIsSubmitted(false); setIsUpdate(false);
            }
        }
    }
    return (
        <div>
            <div className="category-container">
                <span style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 className="text-black">Charges:</h5>
                    <Tooltip title="Update charges" arrow>
                        <i className="fas fa-edit" style={{ cursor: "pointer", color: "blue" }} onClick={() => setIsUpdate(true)} />
                    </Tooltip>
                </span>
                <Divider sx={{ width: "100%", backgroundColor: '#000' }} />
                {isUpdate ? (<>
                    <div className="form-group mb-2">
                        <label className="font-12 mb-0 mr-3">Change Delivary Charge : </label>
                        <input
                            onChange={(e) => setDelivaryCharage(e.target.value)}
                            style={delivaryCharage === "" && isSubmitted ? { border: "1px solid red" } : {}}
                            type="number" value={delivaryCharage} className="form-control" placeholder="Delivary charage"
                        />
                    </div>
                    <div>
                        <div>
                            <div className="form-group mb-2">
                                <label className="font-12 mb-0 mr-3">Change Platform Charge : </label>
                                <input
                                    onChange={(e) => setPlatformCharge(e.target.value)}
                                    style={platformCharge === "" && isSubmitted ? { border: "1px solid red" } : {}}
                                    type="number" value={platformCharge} className="form-control" placeholder="Platform charage"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-right mt-2">
                        <button className="btn btn-verify" onClick={() => submitted()}>Save</button>
                    </div>
                </>
                ) : (
                    <>
                        <div className='mt-3'>
                            <span style={{ display: "flex", justifyContent: "space-between" }}>
                                <h5 className="text-black">Delivary Fees:</h5>
                                <span className='font-20 ml-2'>{delivaryCharage}</span>
                            </span>
                            <span style={{ display: "flex", justifyContent: "space-between" }}>
                                <h5 className="text-black">Platform Fees:</h5>
                                <span className='font-20 ml-2'>{platformCharge}</span>
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default FormSetings;